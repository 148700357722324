var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-light pb-3 my-3 shadow-sm",staticStyle:{"border-bottom-left-radius":"10px","border-bottom-right-radius":"10px"}},[_c('div',{staticClass:"bg-light overflow-auto"},[_c('div',{staticClass:"d-flex shadow-sm",staticStyle:{"position":"sticky","top":"0"}},_vm._l((_vm.fieldsProp),function(field,index){return _c('div',{key:field.key,staticClass:"bg-primary text-light font-weight-bold py-3 d-flex justify-content-center align-items-center",style:({
          width: field.fixedWidth,
          flexGrow: 0,
          flexShrink: 0,
          borderRight: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
          borderTop: '1px solid #ccc',
          borderLeft: index === 0 ? '1px solid #ccc' : ''
        })},[_c('span',[_vm._v(_vm._s(field.label))])])}),0),_c('div',{staticClass:"table-body-style"},_vm._l((_vm.itemsProp),function(item){return _c('div',{key:item.id,staticClass:"d-flex",on:{"click":function($event){return _vm.itemClicked(item.id)},"dblclick":function($event){return _vm.itemDoubleClicked(item.id)}}},_vm._l((_vm.fieldsProp),function(field,index){return _c('div',{key:field.key,staticClass:"py-3 px-3 d-flex align-items-center",style:({
            width: field.fixedWidth,
            flexGrow: 0,
            flexShrink: 0,
            borderRight: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
            borderLeft: index === 0 ? '1px solid #ccc' : '',
            backgroundColor: item.id === _vm.itemIdSelected ? 'lightblue' : ''
          })},[_c('span',{staticClass:"text-break"},[_vm._v(_vm._s(item[field.key] ? typeof item[field.key] === 'string' ? item[field.key].trim().length > 0 ? item[field.key] : '-' : item[field.key] : '-'))])])}),0)}),0)]),_c('div',{staticClass:"d-flex justify-content-center align-items-center pt-3"},[_c('b-button',{staticClass:"font-weight-bold mr-3",attrs:{"variant":"primary","size":"sm","disabled":_vm.pageProp <= 1},on:{"click":function($event){return _vm.paginationButtonClicked('FIRST')}}},[_c('span',[_vm._v(" "+_vm._s('แรกสุด')+" ")])]),_c('b-button',{staticClass:"font-weight-bold mr-3",attrs:{"variant":"primary","size":"sm","disabled":_vm.pageProp <= 1},on:{"click":function($event){return _vm.paginationButtonClicked('PREV')}}},[_c('span',[_vm._v(" "+_vm._s('ก่อนหน้า')+" ")])]),_c('b-button',{staticClass:"font-weight-bold mr-3",attrs:{"variant":"outline-primary","disabled":""}},[_c('span',[_vm._v(" "+_vm._s(`หน้าที่ ${_vm.pageProp}`)+" ")])]),_c('b-button',{staticClass:"font-weight-bold mr-3",attrs:{"variant":"primary","size":"sm","disabled":!_vm.totalCountProp || _vm.pageProp === Math.ceil(_vm.totalCountProp / _vm.limitProp)},on:{"click":function($event){return _vm.paginationButtonClicked('NEXT')}}},[_c('span',[_vm._v(" "+_vm._s('ถัดไป')+" ")])]),_c('b-button',{staticClass:"font-weight-bold",attrs:{"variant":"primary","size":"sm","disabled":!_vm.totalCountProp || _vm.pageProp === Math.ceil(_vm.totalCountProp / _vm.limitProp)},on:{"click":function($event){return _vm.paginationButtonClicked('LAST')}}},[_c('span',[_vm._v(_vm._s('ท้ายสุด'))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }