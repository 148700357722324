<script>
export default {
  props: {
    keywordProp: String
  },
  data() {
    return {
      keyword: null
    }
  },
  watch: {
    keyword() {
      if (this.keyword !== this.keywordProp) {
        this.$emit('keywordChanged', this.keyword)
      }
    },
    keywordProp() {
      if (this.keyword !== this.keywordProp) {
        this.keyword = this.keywordProp
      }
    }
  }
}
</script>

<template>
  <div>
    <label class="text-white text-sm" for="search-input"> ค้นหา </label>
    <b-form-input
      id="search-input"
      v-model="keyword"
      :placeholder="'คำค้นหา'"
    ></b-form-input>
  </div>
</template>

<style></style>
