<script>
export default {
  props: {
    selectedProp: String
  },
  data() {
    return {
      searchBySelected: 'customerId',
      searchByOptions: [
        { value: 'customerId', text: 'รหัสลูกค้า' },
        { value: 'customerName', text: 'ชื่อลูกค้า' },
        { value: 'contact', text: 'ผู้ติดต่อ' },
        { value: 'address', text: 'ที่อยู่' },
        { value: 'province', text: 'จังหวัด' },
        { value: 'zipCode', text: 'รหัสไปรษณีย์' },
        { value: 'telephoneNumber', text: 'โทรศัพท์' },
        { value: 'taxId', text: 'หมายเลขประจำตัวผู้เสียภาษี' }
      ]
    }
  },
  watch: {
    selectedProp() {
      if (this.searchBySelected !== this.selectedProp) {
        this.searchBySelected = this.selectedProp
      }
    },
    searchBySelected() {
      if (this.searchBySelected !== this.selectedProp) {
        this.$emit('selectedChanged', this.searchBySelected)
      }
    }
  },
  created() {
    this.searchBySelected = this.selectedProp
  }
}
</script>

<template>
  <div>
    <label class="text-white text-sm" for="sort-by-selection"> ค้นหาโดย </label>
    <b-form-select
      id="sort-by-selection"
      v-model="searchBySelected"
      :options="searchByOptions"
    ></b-form-select>
  </div>
</template>

<style></style>
