<script>
export default {
  props: {
    fieldsProp: Array,
    itemsProp: Array,
    itemSelectedProp: Object,
    totalCountProp: Number,
    pageProp: Number,
    limitProp: Number
  },
  data() {
    return {
      itemIdSelected: null
    }
  },
  watch: {
    itemSelectedProp() {
      if (this.itemSelectedProp === null && this.itemIdSelected !== null) {
        this.itemIdSelected = null
      }
      if (
        this.itemSelectedProp &&
        this.itemSelectedProp.id !== this.itemIdSelected
      ) {
        this.itemIdSelected = this.itemSelectedProp.id
      }
    }
  },
  methods: {
    itemClicked(itemId) {
      if (this.itemIdSelected === itemId) {
        this.itemIdSelected = null
        this.$emit('itemSelectedChanged', null)
      } else {
        const foundItem = this.itemsProp.find((item) => item.id === itemId)
        this.itemIdSelected = foundItem.id
        this.$emit('itemSelectedChanged', foundItem)
      }
    },
    itemDoubleClicked(itemId) {
      this.$router.push({
        name: 'AccountReceivable.Detail',
        params: { id: itemId }
      })
    },
    paginationButtonClicked(type) {
      switch (type) {
        case 'FIRST':
          this.$emit('pageChanged', 1)
          break
        case 'PREV':
          this.$emit('pageChanged', this.pageProp - 1)
          break
        case 'NEXT':
          this.$emit('pageChanged', this.pageProp + 1)
          break
        case 'LAST':
          this.$emit(
            'pageChanged',
            Math.ceil(this.totalCountProp / this.limitProp)
          )
          break
        default:
          break
      }
    }
  }
}
</script>

<template>
  <div
    class="bg-light pb-3 my-3 shadow-sm"
    style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px"
  >
    <div class="bg-light overflow-auto">
      <!-- table header -->
      <div class="d-flex shadow-sm" style="position: sticky; top: 0">
        <div
          v-for="(field, index) in fieldsProp"
          :key="field.key"
          class="bg-primary text-light font-weight-bold py-3 d-flex justify-content-center align-items-center"
          :style="{
            width: field.fixedWidth,
            flexGrow: 0,
            flexShrink: 0,
            borderRight: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
            borderTop: '1px solid #ccc',
            borderLeft: index === 0 ? '1px solid #ccc' : ''
          }"
        >
          <span>{{ field.label }}</span>
        </div>
      </div>

      <!-- table body -->
      <div class="table-body-style">
        <div
          v-for="item in itemsProp"
          :key="item.id"
          class="d-flex"
          @click="itemClicked(item.id)"
          @dblclick="itemDoubleClicked(item.id)"
        >
          <div
            v-for="(field, index) in fieldsProp"
            :key="field.key"
            class="py-3 px-3 d-flex align-items-center"
            :style="{
              width: field.fixedWidth,
              flexGrow: 0,
              flexShrink: 0,
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderLeft: index === 0 ? '1px solid #ccc' : '',
              backgroundColor: item.id === itemIdSelected ? 'lightblue' : ''
            }"
          >
            <span class="text-break">{{
              item[field.key]
                ? typeof item[field.key] === 'string'
                  ? item[field.key].trim().length > 0
                    ? item[field.key]
                    : '-'
                  : item[field.key]
                : '-'
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- table pagination -->
    <div class="d-flex justify-content-center align-items-center pt-3">
      <b-button
        class="font-weight-bold mr-3"
        variant="primary"
        size="sm"
        :disabled="pageProp <= 1"
        @click="paginationButtonClicked('FIRST')"
      >
        <span>
          {{ 'แรกสุด' }}
        </span>
      </b-button>
      <b-button
        class="font-weight-bold mr-3"
        variant="primary"
        size="sm"
        :disabled="pageProp <= 1"
        @click="paginationButtonClicked('PREV')"
      >
        <span>
          {{ 'ก่อนหน้า' }}
        </span>
      </b-button>
      <b-button
        class="font-weight-bold mr-3"
        variant="outline-primary"
        disabled
      >
        <span>
          {{ `หน้าที่ ${pageProp}` }}
        </span>
      </b-button>
      <b-button
        class="font-weight-bold mr-3"
        variant="primary"
        size="sm"
        :disabled="
          !totalCountProp || pageProp === Math.ceil(totalCountProp / limitProp)
        "
        @click="paginationButtonClicked('NEXT')"
      >
        <span>
          {{ 'ถัดไป' }}
        </span>
      </b-button>
      <b-button
        class="font-weight-bold"
        variant="primary"
        size="sm"
        :disabled="
          !totalCountProp || pageProp === Math.ceil(totalCountProp / limitProp)
        "
        @click="paginationButtonClicked('LAST')"
      >
        <span>{{ 'ท้ายสุด' }}</span>
      </b-button>
    </div>
  </div>
</template>

<style scoped>
.table-body-style {
  height: calc(100vh - 350px);
  cursor: pointer;
}

@media (max-width: 1200px) {
  .table-body-style {
    height: calc(100vh - 420px);
  }
}

@media (max-width: 992px) {
  .table-body-style {
    height: calc(100vh - 420px);
  }
}

@media (max-width: 768px) {
  .table-body-style {
    height: calc(100vh - 620px);
  }
}

@media (max-width: 576px) {
  .table-body-style {
    height: calc(100vh - 620px);
  }
}
</style>
