<script>
export default {
  props: {
    selectedProp: String
  },
  data() {
    return {
      sortBySelected: 'createdAt',
      sortByOptions: [
        { value: 'createdAt', text: 'สร้างเมื่อ' },
        { value: 'updatedAt', text: 'แก้ไขล่าสุดเมื่่อ' }
      ]
    }
  },
  watch: {
    selectedProp() {
      if (this.sortBySelected !== this.selectedProp) {
        this.sortBySelected = this.selectedProp
      }
    },
    sortBySelected() {
      if (this.sortBySelected !== this.selectedProp) {
        this.$emit('selectedChanged', this.sortBySelected)
      }
    }
  },
  created() {
    this.sortBySelected = this.selectedProp
  }
}
</script>

<template>
  <div>
    <label class="text-white text-sm" for="sort-by-selection"> เรียงตาม </label>
    <b-form-select
      id="sort-by-selection"
      v-model="sortBySelected"
      :options="sortByOptions"
    ></b-form-select>
  </div>
</template>

<style></style>
