<script>
import { toastMixins } from '@/mixins'

import DeleteModal from './DeleteModal.vue'

export default {
  mixins: [toastMixins],
  props: {
    accountReceivableProp: Object
  },
  components: { DeleteModal },
  data() {
    return {
      buttonClass:
        'shadow m-1 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2',
      modalShow: false,
      modalTitle: null,
      modalThemeColor: null,
      modalCurrent: null,
      errMsg: null
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'CREATE':
          this.$router.push({
            name: 'AccountReceivable.Create'
          })
          break
        case 'UPDATE':
          if (!this.accountReceivableProp) {
            alert('เลือกลูกค้าลูกหนี้ที่ต้องการจะแก้ไข')
            return
          }
          this.$router.push({
            name: 'AccountReceivable.Edit',
            params: {
              id: this.accountReceivableProp.id
            }
          })
          break
        case 'DELETE':
          if (!this.accountReceivableProp) {
            alert('เลือกลูกค้าลูกหนี้ที่ต้องการจะลบ')
            return
          }
          this.modalShow = true
          this.modalTitle = 'ลบลูกค้าลูกหนี้'
          this.modalThemeColor = 'danger'
          this.modalCurrent = 'delete-modal'
          break
        case 'READ':
          if (!this.accountReceivableProp) {
            alert('เลือกลูกค้าลูกหนี้ที่ต้องการดูรายละเอียด')
            return
          }
          this.$router.push({
            name: 'AccountReceivable.Detail',
            params: {
              id: this.accountReceivableProp.id
            }
          })
          break
        case 'EXIT':
          this.$router.push({
            name: 'Home'
          })
          break
      }
    },
    closeModalHandler(event) {
      switch (event.type) {
        case 'CLOSE':
          this.modalShow = false
          break
        case 'CLOSE_WITH_SUCCESS':
          this.modalShow = false
          this.mxDisplaySuccessMessage(event.message)
          this.$emit('updateTable')
          break
        case 'DISPLAY_ERROR':
          this.errMsg = event.message
          break
        default:
          this.modalShow = false
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- CREATE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="success"
          @click="buttonClicked('CREATE')"
          v-b-tooltip.hover.bottom.v-success="'สร้างลูกค้าลูกหนี้รายใหม่'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="plus"></b-icon>
          <span class="text-sm">สร้าง</span>
        </b-button>
      </div>

      <!-- UPDATE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขข้อมูลลูกค้าลูกหนี้'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="pencil-square"></b-icon>
          <span class="text-sm">แก้ไข</span>
        </b-button>
      </div>

      <!-- DELETE BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('DELETE')"
          v-b-tooltip.hover.bottom.v-danger="'ลบลูกค้าลูกหนี้'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="trash"></b-icon>
          <span class="text-sm">ลบ</span>
        </b-button>
      </div>

      <!-- READ BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="warning"
          @click="buttonClicked('READ')"
          v-b-tooltip.hover.bottom.v-warning="'ดูข้อมูลลูกค้าลูกหนี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="file-earmark-text"
          ></b-icon>
          <span class="text-sm">รายละเอียด</span>
        </b-button>
      </div>

      <!-- EXIT BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('EXIT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="box-arrow-right"
          ></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>

      <!-- MODAL DISPLAY -->
      <b-modal
        v-model="modalShow"
        :title="modalTitle"
        :header-bg-variant="modalThemeColor"
        header-text-variant="light"
        :hide-footer="true"
        :size="'lg'"
      >
        <component
          :is="modalCurrent"
          :accountReceivableProp="accountReceivableProp"
          @closeModal="closeModalHandler"
        ></component>
      </b-modal>
    </div>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
