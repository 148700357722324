<script>
export default {
  props: {
    selectedProp: String
  },
  data() {
    return {
      sortTypeSelected: 'desc',
      sortTypeOptions: [
        { value: 'asc', text: 'น้อย - มาก' },
        { value: 'desc', text: 'มาก - น้อย' }
      ]
    }
  },
  watch: {
    selectedProp() {
      if (this.sortTypeSelected !== this.selectedProp) {
        this.sortTypeSelected = this.selectedProp
      }
    },
    sortTypeSelected() {
      if (this.sortTypeSelected !== this.selectedProp) {
        this.$emit('selectedChanged', this.sortTypeSelected)
      }
    }
  },
  created() {
    this.sortTypeSelected = this.selectedProp
  }
}
</script>

<template>
  <div>
    <label class="text-white text-sm" for="sort-by-selection"> เรียงแบบ </label>
    <b-form-select
      id="sort-by-selection"
      v-model="sortTypeSelected"
      :options="sortTypeOptions"
    ></b-form-select>
  </div>
</template>

<style></style>
