<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import accountReceivableService from '@/services/master/account-receivable'
import { datetimeMixins } from '@/mixins'

import MenuBar from './components/MenuBar.vue'
import SortByOption from './components/SortByOption.vue'
import SortTypeOption from './components/SortTypeOption.vue'
import SearchByOption from './components/SearchByOption.vue'
import SearchInputField from './components/SearchInputField.vue'
import DisplayTable from './components/DisplayTable.vue'

export default {
  mixins: [datetimeMixins],
  components: {
    MenuBar,
    SortByOption,
    SortTypeOption,
    SearchByOption,
    SearchInputField,
    DisplayTable
  },
  data() {
    return {
      sortBySelected: 'createdAt',
      sortTypeSelected: 'desc',
      searchBySelected: 'customerId',
      searchKeyword: null,
      fields: [
        { key: 'customerId', label: 'รหัสลูกค้า', fixedWidth: '150px' },
        { key: 'customerName', label: 'ชื่อลูกค้า', fixedWidth: '200px' },
        { key: 'contact', label: 'ผู้รับการติดต่อ', fixedWidth: '200px' },
        { key: 'telephoneNumber', label: 'โทรศัพท์', fixedWidth: '200px' },
        {
          key: 'address',
          label: 'ที่อยู่ลูกค้า',
          fixedWidth: '400px'
        },
        { key: 'createdAtTH', label: 'สร้างเมื่อ', fixedWidth: '150px' },
        { key: 'updatedAtTH', label: 'อัพเดตเมื่อ', fixedWidth: '150px' }
      ],
      items: [],
      itemSelected: null,
      totalCount: 0,
      page: 1,
      limit: 20,
      searchKeywordTimer: null,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultKeywordTimerMs: (state) => state.constants.defaultKeywordTimerMs
    })
  },
  watch: {
    sortBySelected() {
      this.page = 1
      this.fetchAccountReceivables()
    },
    sortTypeSelected() {
      this.page = 1
      this.fetchAccountReceivables()
    },
    searchBySelected() {
      this.page = 1
      this.fetchAccountReceivables()
    },
    searchKeyword() {
      if (this.searchKeywordTimer) {
        clearTimeout(this.searchKeywordTimer)
      }

      const timer = setTimeout(() => {
        this.page = 1
        this.fetchAccountReceivables()
        this.searchKeywordTimer = null
      }, this.defaultKeywordTimerMs)

      this.searchKeywordTimer = timer
    },
    page() {
      this.fetchAccountReceivables()
    }
  },
  created() {
    this.fetchAccountReceivables()
  },
  methods: {
    async fetchAccountReceivables() {
      try {
        const qs = this.getQueryString()
        const res =
          await accountReceivableService.getPaginationAccountReceivable(qs)
        const { totalCount, accountReceivables } = res.data.data
        this.totalCount = totalCount
        this.items = this.formatItems(accountReceivables)
      } catch (err) {
        this.errMsg = err.response?.data?.thMessage || 'เกิดข้อผิดพลาดในระบบ'
      }
    },
    getQueryString() {
      const qsArray = [
        `sortBy=${this.sortBySelected}`,
        `sortType=${this.sortTypeSelected}`,
        `limit=${this.limit}`,
        `offset=${this.limit * (this.page - 1)}`
      ]

      if (!_.isNil(this.searchKeyword) && _.isString(this.searchKeyword)) {
        const trimKeyword = this.searchKeyword.trim()
        if (trimKeyword.length > 0) {
          qsArray.push(
            `searchBy=${this.searchBySelected}`,
            `keyword=${trimKeyword}`
          )
        }
      }

      return qsArray.join('&')
    },
    formatItems(items) {
      return items.map((item) => {
        return {
          ...item,
          address: `${item.address1 || ''} ${item.address2 || ''} ${
            item.province || ''
          } ${item.zipCode || ''}`,
          createdAtTH: this.mxConvertToDatetimeBuddhistFormat(item.createdAt),
          updatedAtTH: this.mxConvertToDatetimeBuddhistFormat(item.updatedAt)
        }
      })
    },
    selectedChangedHandler(type, event) {
      switch (type) {
        case 'SORT_BY':
          this.sortBySelected = event
          break
        case 'SORT_TYPE':
          this.sortTypeSelected = event
          break
        case 'SEARCH_BY':
          this.searchBySelected = event
          break
        case 'SEARCH_KEYWORD':
          this.searchKeyword = event
          break
      }
    },
    itemSelectedChangedHandler(item) {
      this.itemSelected = item
    },
    pageChangedHandler(newPage) {
      this.page = newPage
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- page title / menu bar -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="ลูกค้าลูกหนี้"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar
          :accountReceivableProp="itemSelected"
          @updateTable="fetchAccountReceivables"
        ></menu-bar>
      </b-col>
    </b-row>

    <!-- sort by / sort type / search by / search input field -->
    <b-row class="flex justify-content-xl-between">
      <b-col cols="12" md="3" xl="2">
        <sort-by-option
          :selectedProp="sortBySelected"
          @selectedChanged="selectedChangedHandler('SORT_BY', $event)"
        ></sort-by-option>
      </b-col>
      <b-col cols="12" md="3" xl="2">
        <sort-type-option
          :selectedProp="sortTypeSelected"
          @selectedChanged="selectedChangedHandler('SORT_TYPE', $event)"
        ></sort-type-option>
      </b-col>
      <b-col class="d-none d-xl-block" xl="3"></b-col>
      <b-col cols="12" md="3" xl="2">
        <search-by-option
          :selectedProp="searchBySelected"
          @selectedChanged="selectedChangedHandler('SEARCH_BY', $event)"
        ></search-by-option>
      </b-col>
      <b-col cols="12" md="3" xl="3">
        <search-input-field
          :keywordProp="searchKeyword"
          @keywordChanged="selectedChangedHandler('SEARCH_KEYWORD', $event)"
        ></search-input-field>
      </b-col>
    </b-row>

    <!-- table -->
    <b-row>
      <b-col>
        <display-table
          :fieldsProp="fields"
          :itemsProp="items"
          :totalCountProp="totalCount"
          :pageProp="page"
          :limitProp="limit"
          :itemSelectedProp="itemSelected"
          @itemSelectedChanged="itemSelectedChangedHandler"
          @pageChanged="pageChangedHandler"
        ></display-table>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
